import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import * as b from "../components/bootstrap.module.css"
import useConfig from '../hooks/useConfig'
import Thumbnail from "../components/thumbnail";
import Partners from "../components/partners";
import RankingWidget from '../components/ranking-widget'
import Icon from '../components/icon'
import Team from '../components/team'
import Translate from '../components/translate'
import BlogContent from '../components/blog-content'
import Layout from '../components/layout'
import siteMetadata from '../utils/site-metadata';
import ProductInputs from '../components/product-inputs'
import AllProducts from '../components/all-products';
import input from '../utils/form-input';


import BaseHead from '../components/base-head';
export const Head = ({ ...props }) => (
  <BaseHead {...props} 
    title="Comparación de productos financial | LoanDO.mx" 
    // description="Platforma loando.pl pozwala bezpłatnie sprawdzić koszty kredytów, pożyczek i kont bankowych. Wszystko online i za darmo!"
  >
    {/* <script type="application/json" dangerouslySetInnerHTML={{ __html: JSON.stringify({
      "@context": "http://schema.org",
      "@type": "AggregateRating",
      "ratingCount": props.data.allMysqlComments.totalCount,
      "reviewCount": props.data.allMysqlComments.totalCount,
      "ratingValue": (props.data.allMysqlComments.nodes.reduce((prev, curr) => prev + curr.rating, 0) / props.data.allMysqlComments.totalCount) % 10
    }) }}></script> */}
    {/* <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify({
      "@context": "http://schema.org",
      "@type":"FinancialService",
      "image": `${siteMetadata.domain}/assets/Logo.svg`,
      "legalName":"Loando Sp. z o.o",
      "name":"Loando.pl",
      "url": `${siteMetadata.domain}/`,
      "priceRange":"100-250000",
      "telephone":"+48511574319",
      "brand": {
        "@type":"Organization",
        "name":"Loando.pl",
        "url": `${siteMetadata.domain}/`,
        "taxID": "5252534867",
        "additionalProperty": [
          {
            "@type": "PropertyValue",
            "name": "KRS",
            "value": "0000548045",
          },
          {
            "@type": "PropertyValue",
            "name": "REGON",
            "value": "361006900"
          }
        ],
      },
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Marka Kotańskiego 8/3",
        "addressLocality": "Olsztyn",
        "email": "kontakt@loando.pl",
        "postalCode": "11-043",
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "53.780832",
        "longitude": "20.470155"
      },
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek": "https://schema.org/Monday",
          "opens": "09:00:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek": "https://schema.org/Tuesday",
          "opens": "09:00:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek":  "https://schema.org/Wednesday",
          "opens": "09:00:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "closes":  "17:00:00",
          "dayOfWeek": "https://schema.org/Thursday",
          "opens": "09:00:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek": "https://schema.org/Friday",
          "opens": "09:00:00"
        },
      ],
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingCount": props.data.allMysqlComments.totalCount,
        "reviewCount": props.data.allMysqlComments.totalCount,
        "ratingValue": (props.data.allMysqlComments.nodes.reduce((prev, curr) => prev + curr.rating, 0) / props.data.allMysqlComments.totalCount) % 10
      },
      "review": props.data.allMysqlComments.nodes.slice(0, 10).map((el) => ({
        "@type": "Review",
        "datePublished": new Date(el.created_date).toISOString(),
        "reviewBody": el.text,
        "reviewRating": {
          "@type": "Rating",
          "bestRating": "5",
          "ratingValue": el.rating,
          "worstRating": "1",
        },
        "author": {
          "@type": "Person",
          "name": el.author,
        },
      }))
    }) }}></script> */}
  </BaseHead>
)


const IndexPage = ({ 
  data: { allMysqlBlog, homeAdvice },
  location: {pathname}
}) => {
  const [product, setProduct] = useState('firm1')
  const [forceUpdate, setForceUpdate] = useState(false);
  const productSettings = useRef({
    input: input['firm_1'],
    types: 'firm', 
    subtypes: {
      firm: '1' 
    },
  });

  const getConfig = useConfig()

  const setProductSettings = (value) => {
    productSettings.current = value;

    setForceUpdate(!forceUpdate);
  };

  const changeProduct = (types, subtypes) => {
    setProductSettings({ 
      input: input[`${types}_${subtypes}`],
      types, 
      subtypes: {
        [types]: subtypes,
      },
    })

    setProduct(`${types}${subtypes}`);
  }

  return (
    <Layout pathname={pathname}>
      <section className={['intro','introMainPage',b.py0].join(' ')}>
        <div className={b.container+' '+b.py3+' '+b.ptMd5+' '+b.positionRelative}>
          <div className={b.row}>
            <div className={b.colXl10}>
              <h1 className={b.textWhite+' '+b.mb4}>
                <Translate tKey="Product comparison"/>{` `}
                <span className={b.dSmBlock}>
                  <Translate tKey="financial"/>
                </span> 
              </h1>
              <p className={['home-page-calc-desc',b.mt3,'display-mobile-btn'].join(' ')}>{getConfig('home_calc_description')}</p>
              <div className={`intro-calculator ${b.p3} ${b.pLg4}`}>
                <div className={'intro-calculator-buttons'}>
                  <Icon isActive={product === 'firm1'} icon="Loans" className={"icon-intro"}>
                    <button onClick={() => changeProduct('firm', '1')} className={product === 'firm1' ? 'active' : ''}>
                      <Translate tKey="Payday loan"/>
                    </button>
                  </Icon>    

                  <Icon icon="Installment-loan" className="icon-intro"  isActive={product === 'firm2'}> 
                    <button onClick={() => changeProduct('firm', '2')} className={product === 'firm2' ? 'active' : ''}>
                      <Translate tKey="Installment loan"/>
                    </button>
                  </Icon>

                  <Icon icon="Cash-loan" className="icon-intro"  isActive={product === 'credits2'}>
                    <button onClick={() => changeProduct('credits', '2')} className={product === 'credits2' ? 'active' : ''}>
                      <Translate tKey="Cash credit"/>
                    </button>
                  </Icon>

                  <Icon icon="Credit-cards" className="icon-intro"  isActive={product === 'cards1'}>
                    <button onClick={() => changeProduct('cards', '1')} className={product === 'cards1' ? 'active' : ''}>
                      <Translate tKey="Credit card"/>
                    </button>
                  </Icon> 
                </div>

                {productSettings.current.types !== 'cards' && (
                  <div className={b.mt3}>
                    <ProductInputs
                      key={product}
                      settings={productSettings.current} 
                      onChangeInput={setProductSettings}
                      productName={product}
                    />
                  </div>
                )}
              </div>
              <p className={['home-page-calc-desc',b.mt3,'display-btn'].join(' ')}>{getConfig('home_calc_description')}</p>
            </div>
          </div>
          <img src="../assets/RysioHOME.svg" className='intro-image' alt="Rysio" loading="lazy" widht="540" height="585"/>
        </div>
      </section>

      <section className={[b.container, b.my5].join(' ')}>
        <h2 className="title">{getConfig(`home_${product}_title`)}</h2>
        <p className={[b.mb4,'body-text-color'].join(' ')}>{getConfig(`home_${product}_description`)}</p>

        <AllProducts settings={productSettings.current} />
      </section>
      
      {/* <section className={[b.container, b.my5, b.pb4, b.pbLg5].join(' ')}>
        <h2 className="title">{getConfig('home_video_header')}</h2>
        
        <div className={[b.mt3, b.mb4].join(' ')}>
           <p className={[b.mt2,'blueToGray'].join(' ')}>{getConfig('home_video_description')}</p>
        </div>
      </section> */}

      <div className={[b.container].join(' ')}>
        <div className={['home-page-app-box',b.p3].join(' ')}>
          <div className={[b.colLg12,b.mb4].join(' ')}>
            <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter].join(' ')}>
              <img src="../assets/Rysiek-shield.svg" alt="Rysiek z tarczą" className='home-page-img' loading="lazy"  width="437" height="396"/>
            </div>
            <h2 className={[b.textCenter,b.mb4].join(' ')}><Translate tKey="Loando security guarantee" /></h2>
            <p className={[b.textCenter,b.fontWeightNormal,'blueToWhite','fs20'].join(' ')}>{getConfig('home_security_loando')}</p>
          </div>
          <div className={[b.row,'advice-app-box'].join(' ')}>
            <div className={[b.colLg3,b.mt2].join(' ')}>
              <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb2].join(' ')}>
                <img src="../assets/shield.svg" alt="tarcza" loading="lazy" width="59" height="79"/>
              </div>
              <div className={[b.textCenter,'font-size-20','blackToWhite'].join(' ')}><Translate tKey="The latest and most secure SSL certificate"/></div>
              <p className={[b.textCenter,b.fontWeightNormal,'blueToWhite',b.pt2].join(' ')}>{getConfig('home_ssl_cert')}</p>
            </div>
            <div className={[b.colLg3,b.mt2].join(' ')}>
              <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb2].join(' ')}>
                <img src="../assets/shield.svg" alt="tarcza" loading="lazy" width="59" height="79"/>
              </div>
              <div className={[b.textCenter,'font-size-20','blackToWhite'].join(' ')}><Translate tKey="Direct cooperation" /></div>
              <p className={[b.textCenter,b.fontWeightNormal,'blueToWhite',b.pt2].join(' ')}>{getConfig('home_direct_cooperation')}</p>
            </div>
            <div className={[b.colLg3,b.mt2].join(' ')}>
              <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb2].join(' ')}>
                <img src="../assets/shield.svg" alt="tarcza" loading="lazy" width="59" height="79"/>
              </div>
              <div className={[b.textCenter,'font-size-20','blackToWhite'].join(' ')}><Translate tKey="International experience" /></div>
              <p className={[b.textCenter,b.fontWeightNormal,'blueToWhite',b.pt2].join(' ')}>{getConfig('home_international_experience')}</p>
            </div>
            <div className={[b.colLg3,b.mt2].join(' ')}>
              <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb2].join(' ')}>
                <img src="../assets/shield.svg" alt="tarcza" loading="lazy" width="59" height="79"/>
              </div>
              <div className={[b.textCenter,'font-size-20','blackToWhite='].join(' ')}><Translate tKey="Encrypted data"/></div>
              <p className={[b.textCenter,b.fontWeightNormal,'blueToWhite',b.pt2].join(' ')}>{getConfig('home_encrypted_data')}</p>
            </div>
          </div>
        </div>
      </div>

      <section className={['fix-home-section', b.container, b.my4, b.myMd5, b.pbLg5].join(' ')}>
        <h2 className="title"><Translate tKey="Financial advice"/></h2>

        <div className={[b.my3, b.colLg6, b.px0].join(' ')}>
          <p className={[b.mt2,'blueToGray', b.mb0].join(' ')}>{getConfig('home_financial_advice')}</p>
        </div>

        <Thumbnail image={homeAdvice} objectFit="contain" className={[b.dNone, b.dLgBlock, b.w100, b.pt3].join(' ')} />

        <div className={[b.row, b.mt3, b.mtLg0].join(' ')}>
          <div className={[b.colMd6, b.colLg4, b.mtSm4, b.mtLg0].join(' ')}>
            <span className={["finanse-advice-number", b.mb2, b.mbLg4].join(' ')}>01</span>
            
            <h3 className="finanse-advice-title"><Translate tKey="We analyze"/></h3>

            <p className={[b.mt3,'blueToGray', b.mb0].join(' ')}>{getConfig('home_analyze')}</p>
          </div>

          <div className={[b.colMd6, b.colLg4, b.mt3, b.mtSm4, b.mtLg0].join(' ')}>
            <span className={["finanse-advice-number", b.mb2, b.mbLg4].join(' ')}>02</span>
            
            <h3 className="finanse-advice-title"><Translate tKey="We advise" /></h3>

            <p className={[b.mt3,'blueToGray', b.mb0].join(' ')}>{getConfig('home_advise')}</p>
          </div>

          <div className={[b.colMd6, b.colLg4, b.mt3, b.mtSm4, b.mtLg0].join(' ')}>
            <span className={["finanse-advice-number", b.mb2, b.mbLg4].join(' ')}>03</span>
            
            <h3 className="finanse-advice-title"><Translate tKey="We recommend"/></h3>

            <p className={[b.mt3,'blueToGray'].join(' ')}>{getConfig('home_recommend')}</p>
          </div>
        </div>
      </section>

      <RankingWidget types="all" theme="column" />

      <div className={[b.pb2, b.pbMd4, b.pbLg5].join(' ')}>
        <Team theme="white">
          <h2 className="title">{getConfig('home_team_title')}</h2>
          <p className={['team-description', b.my4, b.mbLg5,'index-team-text'].join(' ')}>{getConfig('home_team_description')}</p>
        </Team>
      </div>

      <Partners />
      
      {allMysqlBlog.nodes.length > 0 &&
        <div className='home-page-white-bg'>
          <div className={['home-page-blue-box'].join(' ')}>
            <div className={[b.container,'blue-box-px0'].join(' ')}>
              <h2 className={[b.mb2,b.mbLg4,'title'].join(' ')}><Translate tKey="They wrote about us" /></h2>
              <div className={[b.justifyContentBetween,b.row,b.mt2,b.mb3].join(' ')}>
                { allMysqlBlog.nodes.map(({body,mysqlImages,intro,external},i) => (
                    <div className={[b.colLg3,b.colMd6,b.mt3,b.mtLg0].join(' ')} key={i}>
                      <div className={['home-page-white-box',b.p3].join(' ')}>
                        <div>
                          <div className={[b.p3,b.dFlex,b.alignItemsCenter,b.justifyContentCenter,'dark-mode-image'].join(' ')}>
                            <a href={external} target="blank" rel="nofollow noopenener noreferrer"><Thumbnail image={mysqlImages[0]} alt={`image-${i}`} /></a>
                          </div>
                          <a href={external} target="blank" rel="nofollow noopenener noreferrer"><BlogContent content={body} className={[b.my4,b.fontWeightNormal].join(' ')}/></a>
                        </div>
                        <small>{intro}</small>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    allMysqlSeo(filter: { item_id: { eq: 1 }, item_type: { eq: "firms" }}) {
      nodes {
        ...SeoData
      }
    }
    allMysqlBlog(limit: 4, filter: {term_id: {eq: 86}, external: {nin: ["", null]}}) {
      nodes {
        mysqlImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        body
        intro
        external
        image_description
        mysqlId
      }
    }
    homeAdvice: file(name: { eq: "home-advice" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
        )
      }
    }
  }
`