module.exports = {
  'firm_1': { amount: 2_000, time: 30, amount_min: 400, amount_max: 14_000, time_min: 7, time_max: 61 }, /* chwilówka */
  'firm_2': { amount: 10_000, time: 3, amount_min: 1000, amount_max: 500_000, time_min: 1, time_max: 48 }, /* ratalka */
  'firm-without-bik': { amount: 3_500, time: 12, amount_min: 500, amount_max: 28_000, time_min: 1, time_max: 36 }, /* pozyczki bez bik */
  'firm': { amount: 2_000, time: 30, amount_min: 400, amount_max: 14_000, time_min: 7, time_max: 61 }, /* pozostałe firmy */
  
  'credits_1': { amount: 100_000, time: 120, amount_min: 100_000, amount_max: 1_000_000, time_min: 12, time_max: 120 }, /* hipoteczny */
  'credits_2': { amount: 2_000, time: 12, amount_min: 100, amount_max: 600_000, time_min: 1, time_max: 24 }, /* gotówkowy */
  'credits_3': { amount: 100_000, time: 12, amount_min: 100_000, amount_max: 500_000, time_min: 6, time_max: 72 }, /* samochodowy */
  'credits_4': { amount: 25_000, time: 60, amount_min: 20_000, amount_max: 240_000, time_min: 3, time_max: 120 }, /* konsolidacyjny */
};
