import React from 'react'
import Thumbnail from '../components/thumbnail'
import * as b from "../components/bootstrap.module.css"
import { graphql, useStaticQuery, Link } from 'gatsby'
import Icon from "../components/icon.tsx"
import Translate from './translate'
import resolveUrl from '../utils/urls/resolve-url';

const Team = ({ children }) => {
  const { allMysqlTeam } = useStaticQuery(graphql`
    query Team {
      allMysqlTeam(sort: { fields: mysqlId, order: DESC })  {
        nodes {
          name
          title
          mysqlId
          mysqlImage {
            childImageSharp {
              gatsbyImageData(width: 150)
            }
          }
        }
      }
    }
  `)

  return (
    <section className={['team', `team-white`, b.mt4, b.mtLg5].join(' ')}>
      <div className={b.container}>
        {children}
        
        <div className={b.row}>
          {
            allMysqlTeam.nodes.map(({ name, title, mysqlImage, mysqlId }, i) => (
              <article key={i} className={[b.colLg6, b.colXl4, b.mb4].join(' ')}>
                <div className={[b.px3, "team-person product-box"].join(' ')}>
                  <div className={[b.row, b.alignItemsCenter].join(' ')}>
                    <div className={b.colAuto}>
                      <Link to={resolveUrl(name)} aria-label='author'>
                        <Thumbnail image={mysqlImage} className="team-person-image" alt={name + ' | loando.mx'}/>
                      </Link>
                    </div>
                    <div className={[b.col, b.pl2].join(' ')}>
                      <Link to={resolveUrl(name)} aria-label='author'>
                        <span className={['tp-name', 'teamTextColor'].join(' ')}>{name}</span>
                      </Link>
                      <p className={['team-person-title', b.mb0,b.fontWeightNormal,b.mt1].join(' ')}>
                        { mysqlId !== 2704 && mysqlId !== 2702 
                          ? <span className={[b.mr2,'index-team-text'].join(' ')}>
                              <Translate tKey="Guardian of department" />
                              : <span className="team-person-job">{title}</span>
                            </span>
                          : <span className="team-person-job">{title}</span>
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </article>
            ))
          }

          <div className={[b.colLg6, b.colXl4, b.mb4].join(' ')}>
            <a href="https://loandogroup.com/kariera" rel="noopener noreferrer nofollow" target="_blank" className={['product-box', 'product-box-team', b.row, b.alignItemsCenter, b.h100, b.pr2, b.mx0].join(' ')}>
              <div className={[b.col, b.px0].join(' ')}>
                <span className={['tp-name', 'teamTextColor'].join(' ')}><Translate tKey="Maybe you?" /></span>

                <p className={['team-person-title', b.mb0,b.fontWeightNormal,b.mt1].join(' ')}>
                  <span className={[b.mr2,'index-team-text'].join(' ')}>
                    <Translate tKey="Check who we are looking for" />
                  </span> 
                </p>
              </div>

              <div className={b.colAuto}>
                <Icon size={20} icon="Angle-right" className={[b.mr0,"icon-blog"].join(' ')} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
} 

export default Team
